import {
    ref,
    reactive,
    del,
    set,
    computed,
    onActivated,
    onMounted,
    watch,
    getCurrentInstance,
    defineExpose,
    onBeforeUnmount,
    nextTick,
} from "@vue/composition-api";

import firebase from "firebase/app";
import { firebaseConnectRef } from "@/plugins/firebase";

export default {
    props: {},
    emits: [],
    setup(props, { emit }) {
        const { proxy } = getCurrentInstance();
        const formChat = ref({});
        const rules = ref({});

        // 新增欄位
        async function addColumn() {
            try {
                const newKey = await firebase
                    .database()
                    .ref("system_default_message/chats")
                    .push().key;
                set(formChat.value, newKey, {
                    description: {
                        title: {
                            tw: "",
                            en: "",
                        },
                        content: {
                            tw: "",
                            en: "",
                        },
                    },
                });
                set(rules.value, newKey, {
                    description: {
                        title: {
                            tw: [
                                {
                                    required: true,
                                    message: "中文描述為必填",
                                    trigger: "blur",
                                },
                            ],
                            en: [],
                        },
                        content: {
                            tw: [
                                {
                                    required: true,
                                    message: "中文描述為必填",
                                    trigger: "blur",
                                },
                            ],
                            en: [],
                        },
                    },
                });

                console.log("formChat=>", rules.value);
            } catch (err) {
                console.log("addColumn err => ", err);
                proxy.$message({
                    type: "error",
                    message: "新增服務商聊天室樣板訊息key失敗",
                });
            }
        }
        /**
         * 刪除欄位
         * @param { type String(字串) } key 刪除key
         */
        async function dropColmun(key) {
            try {
                await proxy.$confirm(`刪除此訊息樣板${key}?`, "提示", {
                    confirmButtonText: "确定",
                    cancelButtonText: "取消",
                    type: "warning",
                });
                del(formChat.value, key);
                firebase
                    .database()
                    .ref("system_default_message/chats/" + key)
                    .remove();
                proxy.$message({
                    type: "success",
                    message: "刪除成功",
                });
            } catch (err) {
                console.log(err);
            }
        }
        //
        /**
         * 更新會員預設聊天室樣板發送訊息資料
         */
        async function updateFormChat(form) {
            try {
                await firebaseConnectRef("system_default_message/chats").set({
                    ...form,
                });
                proxy.$message({
                    type: "success",
                    message: "更新成功",
                });
            } catch (err) {
                proxy.$message({
                    type: "error",
                    message: "更新失敗",
                });
            }
        }
        /**
         * 表單發送
         * @param { type String(字串) } formName 傳送表單名稱
         * @param { type Object(物件) } form 表單資料
         * @example ref="form"
         */
        async function onSubmitFormChat(formName, form) {
            try {
                // 等待表單驗證是否成功 try catch 會自動判斷是 true 或 false 因次不用寫 if 判斷
                await this.$refs[formName].validate();
                await updateFormChat(form);
                await getList();
            } catch (err) {
                proxy.$message({
                    type: "error",
                    message: "表單驗證失敗",
                });
            }
        }
        // 取得列表
        async function getList() {
            try {
                const data = await firebase
                    .database()
                    .ref("system_default_message/chats")
                    .get();
                // 判斷是否有訊息
                console.log("firebase data =>", data.val());
                if (data.exists()) {
                    formChat.value = {};
                    setDefault(data.val());
                }
            } catch (err) {
                console.log("getList =>", err);
                proxy.$message({
                    type: "error",
                    message: "取得數據失敗",
                });
            }
        }
        // 設定表單資料
        function setDefault(datas) {
            Object.keys(datas).forEach((objKey) => {
                set(formChat.value, objKey, {
                    description: datas[objKey].description,
                });
                Object.keys(formChat.value[objKey]["description"]).forEach(
                    () => {
                        set(rules.value, objKey, {
                            description: {
                                title: {
                                    tw: [
                                        {
                                            required: true,
                                            message: "中文描述為必填",
                                            trigger: "blur",
                                        },
                                    ],
                                },
                                content: {
                                    tw: [
                                        {
                                            required: true,
                                            message: "中文描述為必填",
                                            trigger: "blur",
                                        },
                                    ],
                                },
                            },
                        });
                        set(formChat.value[objKey], "description", {
                            title: {
                                tw: formChat.value[objKey]["description"][
                                    "title"
                                ]["tw"],
                                en: formChat.value[objKey]["description"][
                                    "title"
                                ]["en"],
                            },
                            content: {
                                tw: formChat.value[objKey]["description"][
                                    "content"
                                ]["tw"],
                                en: formChat.value[objKey]["description"][
                                    "content"
                                ]["en"],
                            },
                        });
                    }
                );
            });
        }
        onMounted(async () => {
            await getList();
        });
        return {
            rules,
            formChat,
            addColumn,
            dropColmun,
            onSubmitFormChat,
        };
    },
    render() {
        const tableBody = (
            <tbody>
                {Object.keys(this.formChat).map((key) => {
                    return (
                        <tr key={key}>
                            <td
                                class="w-[50px] text-center cursor-pointer"
                                vOn:click={() => this.dropColmun(key)}
                            >
                                {key}
                                <i class="text-2xl text-orange-600 icofont-ui-delete"></i>
                            </td>
                            <td>
                                {Object.keys(
                                    this.formChat[key]["description"]["title"]
                                ).map((key2) => {
                                    return (
                                        <el-form-item
                                            class="mb-0"
                                            key={"title" + key2}
                                            prop={`${key}.description.title.${key2}`}
                                        >
                                            <label class="mb-0 leading-none">
                                                標題（用戶不會看見）-{key2}
                                                <el-input
                                                    vModel={
                                                        this.formChat[key][
                                                            "description"
                                                        ]["title"][key2]
                                                    }
                                                ></el-input>
                                            </label>
                                        </el-form-item>
                                    );
                                })}
                                {Object.keys(
                                    this.formChat[key]["description"]["content"]
                                ).map((key2) => {
                                    return (
                                        <el-form-item
                                            class="mb-0"
                                            key={"content" + key2}
                                            prop={`${key}.description.content.${key2}`}
                                        >
                                            <label class="mb-0 leading-none">
                                                內容-{key2}
                                                <el-input
                                                    type="textarea"
                                                    rows="5"
                                                    vModel={
                                                        this.formChat[key][
                                                            "description"
                                                        ]["content"][key2]
                                                    }
                                                ></el-input>
                                            </label>
                                        </el-form-item>
                                    );
                                })}
                            </td>
                        </tr>
                    );
                })}
            </tbody>
        );
        return (
            <div class="p-2 px-5 my-1 bg-white rounded-lg">
                <div class="flex items-center">
                    <h1 class="flex-grow my-5 text-xl">
                        系統聊天室回應訊息樣板
                    </h1>
                    <button
                        vPermission={["create"]}
                        class="blue-btn"
                        vOn:click_prevent={() => this.addColumn()}
                    >
                        新增
                    </button>
                </div>
                <el-form
                    ref="formChatRefDom"
                    props={{ model: this.formChat }}
                    rules={this.rules}
                >
                    <table class="myTable">
                        <thead>
                            <tr>
                                <th>編號</th>
                                <th>內容</th>
                            </tr>
                        </thead>
                        {tableBody}
                    </table>
                </el-form>
                <div class="flex justify-center my-5">
                    <el-button
                        vPermission={["create", "update"]}
                        class="mb-10"
                        type="primary"
                        native-type="submit"
                        vOn:click_prevent={() =>
                            this.onSubmitFormChat(
                                "formChatRefDom",
                                this.formChat
                            )
                        }
                    >
                        更新
                    </el-button>
                </div>
            </div>
        );
    },
};
